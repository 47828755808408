import React from "react"
import { Link } from "gatsby"
import allCareersImg from "../../assets/images/career/allCareers.webp"

const CareerCard = ({ data }) => {

  return (
      <div className="container career pt-120">   
        <div className="row">
          <div className="col">
            <img src={allCareersImg} alt= "all post background" />
          </div>
        </div>     
        <div className="row">
          <div className="col pt-3 pb-3">
            <h2 className="pt-5" style={{color: "#1F6BF1"}}>JOB OPPORTUNITIES</h2>
            <p>We believe in better. And we make it happen. Better content. Better products. And better careers. <br />
              Are you interested in working within an agile team? Do you want to be using the latest technology? If so then, one of these opportunities may be for you!</p>
          </div>
        </div>
          {data.map(post => (
            <div key={post.node.id} className="row">
              {post.node.frontmatter.publishToProd && <div className="col-lg-12 col-md-12 col-xs-12 post">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-xs-12 d-flex align-items-center full-description">
                    <Link className="jobTitle" to={`/${post.node.frontmatter.templateKey}/${post.node.frontmatter.path}`}>
                      {post.node.frontmatter.jobTitle}
                    </Link>
                  </div>
                  <div className="col-lg-6 col-md-6 col-xs-12 d-flex align-items-center full-description">
                    {/* <p className="post-details">{post.node.frontmatter.posted}</p> */}
                    <p className="post-details">{post.node.frontmatter.jobType}</p>
                  </div>                      
                </div> 
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-xs-12 d-flex mt-3 mb-1">
                    {post.node.frontmatter.jobDescription}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-xs-12 d-flex mt-3 mb-1">
                    <Link to={`/${post.node.frontmatter.templateKey}/${post.node.frontmatter.path}`} className="default-btn">
                        Read More
                    </Link>
                  </div>
                </div>
              </div>
              }              
            </div>
          ))}
      </div>
  )
}

export default CareerCard
