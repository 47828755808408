import React from "react"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { graphql, StaticQuery } from "gatsby"
import CareerCard from "../components/Career/CareerCard"

const CareerPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query CareerIndexQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "careers" } } }
        ) {
          edges {
            node {
              id
              html
              excerpt
              frontmatter {
                publishToProd
                templateKey
                path
                title
                metaTitle
                date
                image
                blogTitle
                description
                metaDescription
                jobTitle              
                jobType
                posted
                jobDescription
              }
            }
          }
        }
        site {
          siteMetadata {
            title
            env
            imagesPrefix
          }
        }
      }
    `}
    render={data => (
      <Layout location={location} env={data.site.siteMetadata.env}>
        <Navbar />
        <SEO
          postTitle="Careers | BlueMail App"
          postDescription="Everything new about Blue Mail - Redefining Your Email"
          postImage="/img/OG/allCareersOg.png"
          postURL="careers"
          postSEO
        />
        <div className="post-feed-container bg-nav">
          <CareerCard
            data={data.allMarkdownRemark.edges.sort(
              (a, b) =>
                new Date(b.node.frontmatter.posted) -
                new Date(a.node.frontmatter.posted)
            )}
          />
        </div>
        <Footer />
      </Layout>
    )}
  />
)

export default CareerPage